@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Google Font (Poppins) */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap"); */

/* Define Custom Font (The Seasons) */

@font-face {
  font-family: "TheSeasons";
  src: local("theseasons"),
       url("../src/components/fonts/Fontspring-DEMO-theseasons-reg.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}


/* Global Reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Body Styles */
body {
  font-family: "TheSeasons", sans-serif; /* Use custom font for the entire body */
  background-color: #fff;
}

/* Container Styles */
.container {
  max-width: 80%;
  margin: auto; /* Center the container horizontally */
}


button {
  border-radius: 5px;
  /* padding: 17px 30px; */
  /* background: #c2b697; */
  border: none;
  border-radius: 50px;
  /* color: #fff; */
  cursor: pointer;
  /* font-weight: bold; */
}
button i {
  margin-right: 5px;
}
.heading {
  text-align: center;
  width: 60%;
  margin: auto;
}
.heading h1 {
  color: #2d3954;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 35px;
}
.heading p {
  color: #72809d;
}
textarea,
input {
  border: none;
  outline: none;
  background: none;
}
.background {
  padding: 80px 0;
  position: relative;
  background: #f7f9fc;
}

.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}
label {
  color: #2d3954;
  font-size: 14px;
}
.mtop {
  margin-top: 50px;
}
h4 {
  font-weight: 500;
  margin-top: 10px;
}
.shadow {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
}
.padding {
  padding: 80px 0;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.back {
  height: 40vh;
  position: relative;
}
.back img {
  height: 40vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}
.back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background: rgba(17, 40, 72, 0.629);
  z-index: -1;
}
.back .container {
  color: #fff;
  padding: 120px 0;
}
.back h1 {
  font-size: 40px;
  font-weight: 500;
}

@media screen and (max-width: 800px) {
  .grid4,
  .grid3,
  .grid5 {
    grid-template-columns: repeat(2, 1fr);
  }
  .heading {
    width: 100%;
  }
  .container {
    max-width: 90%;
  }
  .back::after,
  .back img,
  .back {
    height: 30vh;
  }
}
